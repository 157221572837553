import React from "react"
import styles from "./textRight.module.css"
import Img from "gatsby-image/index"

export default function TextRight({title, subtitle, text, data, image}) {
  return (
    <div className={styles.main}>
      <div className={styles.right}>
        <Img objectFit="contain" fluid={data[image].childImageSharp.fluid} />
      </div>
      <div className={styles.left}>
          <div className={styles.leftWrapper}>
            <h2>{title}</h2>
            <div className={styles.subtitle}>
              <div className={styles.subtitleLine}></div>
              <div className={styles.subtitleText}>{subtitle}</div>
            </div>
            <h5 className={styles.text}>{text}</h5>
          </div>
      </div>

    </div>
  )
}
