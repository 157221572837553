import React from "react"
import Page from "../../components/page"
import TextLeft from "../../components/textLeft"
import { graphql } from "gatsby"
import TextRight from "../../components/textRight"

export default function AboutGuestbooks({ location, data }) {
  return (
    <Page title={"Guest Books – PhotoTailors"} location={location}>
      <TextLeft
        title={<div>GUEST <br/> Books</div>}
        subtitle="use as a photo guest book or just for writing"
        text={
          <div>
            Create your personalized stunning guest book now!
            <br/>
            <br/>
            We care about the details! You can select from a wide range of cover materials: leatherette (vegan leather),
            linen fabrics or leather. These come in various textures and colours.
            <br/>
            <br/>
            You can personalise the cover with your names, date and location using metal type debossing or UV printing.
            <br/>
            <br/>
            Every guest book is handcrafted by our skilled artisans using only the best available materials. We’re
            raising the bar for both beauty and quality!
          </div>
        }
        data={data}
        image={"albumsAboutHandmade"}
      />
      <TextRight
        title={<div>PHOTO<br/>albums</div>}
        subtitle="turn your guest book into a photo album"
        text={
          <div>
            You can transform your guest book into a photo album by choosing the page spacers option. If you’d like to add Instax mini, Polaroid, Lomo’Instant, OneStep, Leica Sofort or Photo booth pictures select the ‘add page spacers’ option when ordering.
            <br/>
            <br/>
            The page spacers raise the page level and create a small gap so you can add the photos without worrying that the guest book will become bulky or fan out.
          </div>
        }
        data={data}
        image={"guestbooksAboutAlbums"}
      />
      <TextLeft
        title={<div>Lay <br/> FLAT TECHNOLOGY</div>}
        subtitle=""
        text="Our binding technology ensures there is no gap between pages. This flawless finish translates into a beautiful, seamless visual experience. You can thus enjoy all your photos and design in full. Layflat albums are great to showcase impressive panoramic spreads."
        data={data}
        image={"guestbooksAboutLayflat"}
      />
      <TextRight
        title={<div>Thick <br/> Pages</div>}
        subtitle="rigid and durable"
        text="Printed images are fused onto a hard heavyweight board core. The thick, robust core doesn’t bend or curl, giving your album an elegant feel. It is the elite’s preferred choice, reserved only for your most exceptional and defining moments."
        data={data}
        image={"albumsAboutThickPages"}
      />
      <TextLeft
        title={<div>MATERIALS <br/> AND FABRICS</div>}
        subtitle=""
        text="Pick your favourite between eco leather, genuine leather and European linen. These come in different colours and textures. You can check our swatches here."
        data={data}
        image={"albumsAboutFabrics"}
      />
      <TextRight
        title={<div>Cover <br/> styles</div>}
        subtitle=""
        text="Each unique cover style has its own personality – which of them matches your taste? Scroll through a selection of different cover finishes: print, deboss or acrylic inserts. Check our Cover Styles here."
        data={data}
        image={"albumsAboutStyles"}
      />
      <TextLeft
        title={<div>album <br/> boxes</div>}
        subtitle=""
        text="Keep your albums in top shape with our beautiful, eco-friendly bamboo boxes. They have a sleek look thanks to the invisible hinges and modern design lines. You will be surprised at how easy it is to store and use them. The bamboo boxes protect your most cherished memories so you can enjoy them years from now."
        data={data}
        image={"albumsAboutBoxes"}
      />
      <TextRight
        title={<div>album <br/> bags</div>}
        subtitle=""
        text="Looking for something simple, but still want that ‘Wow!’ effect? Choose our canvas bags! You will love to learn that they’re friendly with the environment but stand the test of time like no other. Want to try the canvas fabric out? You can check it here."
        data={data}
        image={"albumsAboutBags"}
      />
    </Page>
  )
}


export const query = graphql`
  query {
    albumsAboutHandmade: file(relativePath: { eq: "albumsAboutHandmade.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
   albumsAboutPrinting: file(relativePath: { eq: "albumsAboutPrinting.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  
  albumsAboutLayflat: file(relativePath: { eq: "albumsAboutLayflat.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
   
   albumsAboutThickPages: file(relativePath: { eq: "albumsAboutThickPages.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  } 
  
  albumsAboutFabrics: file(relativePath: { eq: "albumsAboutFabrics.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  } 
  
  albumsAboutStyles: file(relativePath: { eq: "albumsAboutStyles.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  
  albumsAboutBoxes: file(relativePath: { eq: "albumsAboutBoxes.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  
  albumsAboutBags: file(relativePath: { eq: "albumsAboutBags.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
    
    guestbooksAboutAlbums: file(relativePath: { eq: "guestbooksAboutAlbums.jpg" }) { 
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    } 
    
    guestbooksAboutLayflat: file(relativePath: { eq: "guestbooksAboutLayflat.jpg" }) { 
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
   
   
  }
`